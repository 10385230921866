<template>
  <div ref="chartdiv"></div>
</template>

<script>
import PHVChart, {
  addRange,
  hideRanges,
  highlightRange,
  updateRange
} from "@/charts/PHVChart";
import data from "@/charts/data";

export default {
  name: "Chart",
  props: {
    page: {
      type: String
    },
    type: {
      type: String
    },
    gender: {
      type: String
    },
    active: {
      type: Number
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.chart = PHVChart(this.$refs.chartdiv);
    this.setChartData();
    this.updateChartData();
  },
  watch: {
    $route: {
      handler: function(to, from) {
        this.updateChartData();
        this.$nextTick(() => {
          this.highlightPeriod(this.active);
        });
      },
      immediate: true
    },
    gender: function(newData, oldData) {
      this.updateChartData();
      if (newData === "both") {
        hideRanges(this.chart);
      } else {
        this.highlightPeriod(this.active);
      }
    },
    active: function(newData, oldData) {
      this.highlightPeriod(newData);
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.chart.dispose();
    }
  },
  methods: {
    setChartData() {
      if (this.chart) {
        this.chart.chart.data = data.pd;
        console.log(this.page);
        data[
          `agePeriods_${this.type}_${
            this.gender === "both" ? "boys" : this.gender
          }`
        ].forEach(ageBlock => {
          addRange(this.chart, ageBlock, this.rangeClickHandler);
        });
      }
    },
    updateChartData() {
      if (this.chart) {
        if (this.gender === "both") {
          this.chart.chart.map.getKey("boys").show();
          this.chart.chart.map.getKey("girls").show();
          hideRanges(this.chart);
          return;
        }

        this.chart.chart.map
          .getKey(this.gender === "boys" ? "girls" : "boys")
          .hide();

        this.chart.chart.map.getKey(this.gender).show();
        data[`agePeriods_${this.type}_${this.gender}`].forEach(
          (ageBlock, index) => {
            updateRange(this.chart, ageBlock, index, this.page);
          }
        );
      }
    },
    highlightPeriod(index) {
      if (this.gender !== "both") {
        highlightRange(this.chart, index);
      }
    },
    rangeClickHandler(range) {
      if (this.gender !== "both") {
        this.$emit("click", range);
      }
    }
  }
};
</script>

<style scoped></style>
