import content, {
  tooltips_mental,
  tooltips_physical,
  tooltips_injuries
} from "@/content";

export default {
  agePeriods_physical_boys: [
    {
      id: 1,
      name: "period_1",
      fromAge: 5,
      toAge: 20,
      tooltip: ""
    },
    {
      id: 2,
      name: "period_2",
      fromAge: 5,
      toAge: 11.5,
      tooltip: tooltips_physical[0]
    },
    {
      id: 3,
      name: "period_3",
      fromAge: 11.5,
      toAge: 13.8,
      tooltip: tooltips_physical[1]
    },
    {
      id: 4,
      name: "period_4",
      fromAge: 13.8,
      toAge: 16,
      tooltip: tooltips_physical[2]
    },
    {
      id: 5,
      name: "period_5",
      fromAge: 16,
      toAge: 20,
      tooltip: tooltips_physical[3]
    }
  ],
  agePeriods_physical_girls: [
    {
      id: 1,
      name: "period_1",
      fromAge: 5,
      toAge: 20,
      tooltip: ""
    },
    {
      id: 2,
      name: "period_2",
      fromAge: 5,
      toAge: 9.5,
      tooltip: tooltips_physical[0]
    },
    {
      id: 3,
      name: "period_3",
      fromAge: 9.5,
      toAge: 11.5,
      tooltip: tooltips_physical[1]
    },
    {
      id: 4,
      name: "period_4",
      fromAge: 11.5,
      toAge: 14,
      tooltip: tooltips_physical[2]
    },
    {
      id: 5,
      name: "period_5",
      fromAge: 14,
      toAge: 20,
      tooltip: tooltips_physical[3]
    }
  ],
  agePeriods_mental_boys: [
    {
      id: 1,
      name: "period_1",
      fromAge: 5,
      toAge: 23,
      tooltip: ""
    },
    {
      id: 2,
      name: "period_2",
      fromAge: 5,
      toAge: 9,
      tooltip: tooltips_mental[0]
    },
    {
      id: 3,
      name: "period_3",
      fromAge: 9,
      toAge: 12,
      tooltip: tooltips_mental[1]
    },
    {
      id: 4,
      name: "period_4",
      fromAge: 12,
      toAge: 16,
      tooltip: tooltips_mental[2]
    },
    {
      id: 5,
      name: "period_5",
      fromAge: 16,
      toAge: 23,
      tooltip: tooltips_mental[3]
    },
    {
      id: 6,
      name: "period_6",
      fromAge: 19,
      toAge: 23,
      tooltip: tooltips_mental[4]
    }
  ],
  agePeriods_mental_girls: [
    {
      id: 1,
      name: "period_1",
      fromAge: 5,
      toAge: 21,
      tooltip: ""
    },
    {
      id: 2,
      name: "period_2",
      fromAge: 5,
      toAge: 8,
      tooltip: tooltips_mental[0]
    },
    {
      id: 3,
      name: "period_3",
      fromAge: 8,
      toAge: 11,
      tooltip: tooltips_mental[1]
    },
    {
      id: 4,
      name: "period_4",
      fromAge: 11,
      toAge: 15,
      tooltip: tooltips_mental[2]
    },
    {
      id: 5,
      name: "period_5",
      fromAge: 15,
      toAge: 21,
      tooltip: tooltips_mental[3]
    },
    {
      id: 6,
      name: "period_6",
      fromAge: 18,
      toAge: 21,
      tooltip: tooltips_mental[4]
    }
  ],

  agePeriods_injuries_boys: [
    {
      id: 1,
      name: "period_1",
      fromAge: 5,
      toAge: 20,
      tooltip: ""
    },
    {
      id: 2,
      name: "period_2",
      fromAge: 5,
      toAge: 11.5,
      tooltip: tooltips_injuries[0]
    },
    {
      id: 3,
      name: "period_3",
      fromAge: 11.5,
      toAge: 13.8,
      tooltip: tooltips_injuries[1]
    },
    {
      id: 4,
      name: "period_4",
      fromAge: 13.8,
      toAge: 16,
      tooltip: tooltips_injuries[2]
    },
    {
      id: 5,
      name: "period_5",
      fromAge: 16,
      toAge: 20,
      tooltip: tooltips_injuries[3]
    }
  ],
  agePeriods_injuries_girls: [
    {
      id: 1,
      name: "period_1",
      fromAge: 5,
      toAge: 20,
      tooltip: ""
    },
    {
      id: 2,
      name: "period_2",
      fromAge: 5,
      toAge: 9.5,
      tooltip: tooltips_injuries[0]
    },
    {
      id: 3,
      name: "period_3",
      fromAge: 9.5,
      toAge: 11.5,
      tooltip: tooltips_injuries[1]
    },
    {
      id: 4,
      name: "period_4",
      fromAge: 11.5,
      toAge: 14,
      tooltip: tooltips_injuries[2]
    },
    {
      id: 5,
      name: "period_5",
      fromAge: 14,
      toAge: 20,
      tooltip: tooltips_injuries[3]
    }
  ],
  pd: [
    { age: 5, girls: 7, boys: 6.9 },
    { age: 6, girls: 6.6, boys: 6.6 },
    { age: 7, girls: 6.15, boys: 6.2 },
    { age: 8, girls: 5.7, boys: 5.8 },
    { age: 9, girls: 5.7, boys: 5.4 },
    { age: 10, girls: 5.9, boys: 5.15 },
    { age: 11, girls: 6.3, boys: 5.15 },
    { age: 11.5, girls: 6.4, boys: 5.1 },
    { age: 12, girls: 6.1, boys: 5.5 },
    { age: 13, girls: 4.8, boys: 6.8 },
    { age: 13.5, girls: 3.8, boys: 7.2 },
    { age: 14, girls: 2.8, boys: 6.9 },
    { age: 15, girls: 1.4, boys: 5.1 },
    { age: 16, girls: 0.7, boys: 2.8 },
    { age: 17, girls: 0.5, boys: 1.1 },
    { age: 18, girls: 0.3, boys: 0.5 },
    { age: 19, girls: 0.2, boys: 0.2 },
    { age: 20, girls: 0, boys: 0 }
  ]
};
