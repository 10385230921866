import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const rangeOpacityOn = 0.6;
const rangeOpacityNone = 0.05;
const rangeOpacityOff = 0;

export default ref => {
  let chart = am4core.create(ref, am4charts.XYChart);

  let ageAxis = chart.xAxes.push(new am4charts.ValueAxis());
  //valueAxis.tooltip.disabled = true;
  ageAxis.min = 5;
  ageAxis.max = 20;
  ageAxis.strictMinMax = true;
  ageAxis.renderer.minGridDistance = 1;
  ageAxis.title.text = "Biologische leeftijd (j)";

  let phvAxis = chart.yAxes.push(new am4charts.ValueAxis());
  phvAxis.min = 0;
  phvAxis.max = 10;
  phvAxis.strictMinMax = true;
  phvAxis.renderer.minGridDistance = 1;
  phvAxis.title.text = "Groeisnelheid (cm/j)";
  phvAxis.renderer.grid.template.location = 0;

  createSeries(chart, "boys", phvAxis, ageAxis);
  createSeries(chart, "girls", phvAxis, ageAxis);
  const series = createSeries(chart, "range", phvAxis, ageAxis);

  //series.tooltipText = "{valueY.value}";
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = "none";

  return { chart, series, ageAxis };
};

function createSeries(chart, field, phvAxis, ageAxis) {
  let series = chart.series.push(new am4charts.LineSeries());
  series.id = field;
  series.strokeWidth = 3;
  series.dataFields.valueX = "age";
  series.dataFields.valueY = field;

  return series;
}

export function updateRange(chart, data, index, type) {
  const range = chart.series.axisRanges.values[index];
  if (!range) return;
  range.value = data.fromAge;
  range.endValue = data.toAge;
  range.axisFill.fillOpacity = rangeOpacityNone;
  range.axisFill.tooltipText = data.tooltip[type]
    ? data.tooltip[type]
    : data.tooltip;
}

export function resetHighlightRange(chart) {
  chart.series.axisRanges.values.forEach(range => {
    range.axisFill.fillOpacity = rangeOpacityNone;
    range.axisFill.strokeOpacity = 1;
    range.axisFill.tooltip.disabled = false;
  });
}

export function hideRanges(chart) {
  chart.series.axisRanges.values.forEach(range => {
    range.axisFill.fillOpacity = rangeOpacityOff;
    range.axisFill.strokeOpacity = 0;
    range.axisFill.tooltip.disabled = true;
  });
}

export function highlightRange(chart, index) {
  resetHighlightRange(chart);
  const range = chart.series.axisRanges.values[index];
  if (!range) return;
  range.axisFill.fillOpacity = rangeOpacityOn;
  range.axisFill.fill = "#336699";
}

export function addRange(chart, rangeData, event) {
  let range = chart.ageAxis.createSeriesRange(chart.series);
  range.value = rangeData.fromAge;
  range.endValue = rangeData.toAge;
  range.axisFill.interactionsEnabled = true;
  range.axisFill.id = rangeData.id;
  range.axisFill.name = rangeData.name;
  // range.axisFill.fill = am4core.color(rangeData.color);
  range.axisFill.fill = "#33669999";
  range.axisFill.stroke = "#0005";
  range.axisFill.strokeDasharray = "8,8";

  range.axisFill.fillOpacity = rangeOpacityOff;
  range.grid.strokeOpacity = 0;

  range.axisFill.tooltip = new am4core.Tooltip();
  range.axisFill.tooltipText = "";
  range.axisFill.tooltip.label.maxWidth = 250;
  range.axisFill.tooltip.label.wrap = true;
  range.axisFill.tooltipPosition = "pointer";
  range.axisFill.tooltip.showInViewport = true;
  range.axisFill.tooltip.fitPointerToBounds = true;
  range.axisFill.tooltip.setBounds(chart);
  range.axisFill.tooltip.fillOpacity = 0.8;
  range.axisFill.isMeasured = true;

  range.axisFill.events.on(
    "hit",
    function(ev) {
      event(ev.target.id);
    },
    this
  );
}
