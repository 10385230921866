var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-layout c-content__body"},[_c('div',{staticClass:"o-layout__item u-3-of-4-at-medium u-2-of-3-at-large"},[_c('div',{staticClass:"c-content__chart-selectors"},[_c('div',{staticClass:"c-input-group"},[_c('div',{class:[
            'c-input-select',
            _vm.gender.value === 'both' && 'c-input--disabled'
          ]},[_c('label',{staticClass:"c-input__label",attrs:{"for":"period"}},[_vm._v("Periode")]),(_vm.gender.value === 'both')?_c('select',{staticClass:"c-input-select__field",attrs:{"disabled":""}},[_c('option',{attrs:{"value":""}},[_vm._v(" Kies het geslacht om de info per leeftijd te bekijken ")])]):_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.agePeriod),expression:"agePeriod",modifiers:{"number":true}}],staticClass:"c-input-select__field",attrs:{"id":"period"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.agePeriod=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.agePeriods),function(period,index){return _c('option',{key:period.id,domProps:{"value":index}},[_vm._v(" "+_vm._s(period.title)+" ")])}),0)]),_c('div',{staticClass:"c-input-switch"},_vm._l((_vm.genders),function(g){return _c('div',{key:g.value,staticClass:"c-input-switch__option"},[_c('input',{staticClass:"c-input-switch__radio-input",attrs:{"type":"radio","name":"gender","id":g.value},domProps:{"value":g.value,"checked":g.value === _vm.gender.value},on:{"input":function($event){return _vm.onGenderSelect(g)}}}),_c('label',{staticClass:"c-input__label",attrs:{"for":g.value}},[_vm._v(" "+_vm._s(g.label)+" ")])])}),0)]),(_vm.gender.value !== 'both')?_c('div',{staticClass:"c-content__main-image-wrapper u-hidden-at-medium"},[_c('img',{staticClass:"c-content__main-image",attrs:{"src":(_vm.$publicPath + "/assets/" + (_vm.gender.value) + "-" + (_vm.$route.meta.id === 'injuriesPeriods'
                ? 'injuries'
                : _vm.$route.meta.id === 'mentalPeriods'
                ? 'mental'
                : _vm.$route.meta.id) + ".png"),"alt":""}})]):_c('div',{staticClass:"c-content__main-image-wrapper c-content__main-image-wrapper-group u-hidden-at-medium"},[_c('img',{staticClass:"c-content__main-image u-1-of-2",attrs:{"src":(_vm.$publicPath + "/assets/boys-" + (_vm.$route.meta.id === 'injuriesPeriods'
                ? 'injuries'
                : _vm.$route.meta.id === 'mentalPeriods'
                ? 'mental'
                : _vm.$route.meta.id) + ".png"),"alt":""}}),_c('img',{staticClass:"c-content__main-image u-1-of-2",attrs:{"src":(_vm.$publicPath + "/assets/girls-" + (_vm.$route.meta.id === 'injuriesPeriods'
                ? 'injuries'
                : _vm.$route.meta.id === 'mentalPeriods'
                ? 'mental'
                : _vm.$route.meta.id) + ".png"),"alt":""}})])]),_c('div',{staticClass:"o-layout__item u-1-of-1 c-content__chart-wrapper"},[_c('Chart',{staticClass:"c-content__chart",attrs:{"page":_vm.page,"type":_vm.pageType,"gender":_vm.gender.value,"active":_vm.agePeriod},on:{"click":_vm.onRangeClick}})],1)]),(_vm.gender.value !== 'both')?_c('div',{staticClass:"o-layout__item u-1-of-4-at-medium u-1-of-3-at-large c-content__main-image-wrapper u-hidden-until-medium"},[_c('img',{staticClass:"c-content__main-image",attrs:{"src":(_vm.$publicPath + "/assets/" + (_vm.gender.value) + "-" + (_vm.$route.meta.id === 'injuriesPeriods'
            ? 'injuries'
            : _vm.$route.meta.id === 'mentalPeriods'
            ? 'mental'
            : _vm.$route.meta.id) + ".png"),"alt":""}})]):_c('div',{staticClass:"o-layout__item u-1-of-4-at-medium u-1-of-3-at-large c-content__main-image-wrapper u-hidden-until-medium"},[_c('img',{staticClass:"c-content__main-image u-1-of-2",attrs:{"src":(_vm.$publicPath + "/assets/boys-" + (_vm.$route.meta.id === 'injuriesPeriods'
            ? 'injuries'
            : _vm.$route.meta.id === 'mentalPeriods'
            ? 'mental'
            : _vm.$route.meta.id) + ".png"),"alt":""}}),_c('img',{staticClass:"c-content__main-image u-1-of-2",attrs:{"src":(_vm.$publicPath + "/assets/girls-" + (_vm.$route.meta.id === 'injuriesPeriods'
            ? 'injuries'
            : _vm.$route.meta.id === 'mentalPeriods'
            ? 'mental'
            : _vm.$route.meta.id) + ".png"),"alt":""}})]),_c('div',{staticClass:"o-layout__item c-content__text",domProps:{"innerHTML":_vm._s(_vm.htmlContent)}}),(_vm.htmlContentReferences.length > 0)?_c('div',{staticClass:"o-layout__item c-content__text"},[_c('div',{staticClass:"c-content__text-ref",domProps:{"innerHTML":_vm._s(_vm.htmlContentReferences)}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }