<template>
  <div class="o-layout c-content__body">
    <div class="o-layout__item u-3-of-4-at-medium u-2-of-3-at-large">
      <div class="c-content__chart-selectors">
        <div class="c-input-group">
          <div
            :class="[
              'c-input-select',
              gender.value === 'both' && 'c-input--disabled'
            ]"
          >
            <label class="c-input__label" for="period">Periode</label>
            <select
              class="c-input-select__field"
              v-if="gender.value === 'both'"
              disabled
            >
              <option value="">
                Kies het geslacht om de info per leeftijd te bekijken
              </option>
            </select>
            <select
              class="c-input-select__field"
              id="period"
              v-model.number="agePeriod"
              v-else
            >
              <option
                v-for="(period, index) in agePeriods"
                :key="period.id"
                :value="index"
              >
                {{ period.title }}
              </option>
            </select>
          </div>
          <div class="c-input-switch">
            <div
              v-for="g in genders"
              :key="g.value"
              class="c-input-switch__option"
            >
              <input
                type="radio"
                name="gender"
                :id="g.value"
                class="c-input-switch__radio-input"
                :value="g.value"
                :checked="g.value === gender.value"
                @input="onGenderSelect(g)"
              />
              <label :for="g.value" class="c-input__label">
                {{ g.label }}
              </label>
            </div>
          </div>
        </div>

        <div
          class="c-content__main-image-wrapper u-hidden-at-medium"
          v-if="gender.value !== 'both'"
        >
          <img
            class="c-content__main-image"
            :src="
              `${$publicPath}/assets/${gender.value}-${
                $route.meta.id === 'injuriesPeriods'
                  ? 'injuries'
                  : $route.meta.id === 'mentalPeriods'
                  ? 'mental'
                  : $route.meta.id
              }.png`
            "
            alt=""
          />
        </div>
        <div
          v-else
          class="c-content__main-image-wrapper c-content__main-image-wrapper-group u-hidden-at-medium"
        >
          <img
            class="c-content__main-image u-1-of-2"
            :src="
              `${$publicPath}/assets/boys-${
                $route.meta.id === 'injuriesPeriods'
                  ? 'injuries'
                  : $route.meta.id === 'mentalPeriods'
                  ? 'mental'
                  : $route.meta.id
              }.png`
            "
            alt=""
          /><img
            class="c-content__main-image u-1-of-2"
            :src="
              `${$publicPath}/assets/girls-${
                $route.meta.id === 'injuriesPeriods'
                  ? 'injuries'
                  : $route.meta.id === 'mentalPeriods'
                  ? 'mental'
                  : $route.meta.id
              }.png`
            "
            alt=""
          />
        </div>
      </div>

      <div class="o-layout__item u-1-of-1 c-content__chart-wrapper">
        <Chart
          :page="page"
          :type="pageType"
          :gender="gender.value"
          :active="agePeriod"
          @click="onRangeClick"
          class="c-content__chart"
        />
      </div>
    </div>

    <div
      v-if="gender.value !== 'both'"
      class="o-layout__item u-1-of-4-at-medium u-1-of-3-at-large c-content__main-image-wrapper u-hidden-until-medium"
    >
      <img
        class="c-content__main-image"
        :src="
          `${$publicPath}/assets/${gender.value}-${
            $route.meta.id === 'injuriesPeriods'
              ? 'injuries'
              : $route.meta.id === 'mentalPeriods'
              ? 'mental'
              : $route.meta.id
          }.png`
        "
        alt=""
      />
    </div>
    <div
      v-else
      class="o-layout__item u-1-of-4-at-medium u-1-of-3-at-large c-content__main-image-wrapper u-hidden-until-medium"
    >
      <img
        class="c-content__main-image u-1-of-2"
        :src="
          `${$publicPath}/assets/boys-${
            $route.meta.id === 'injuriesPeriods'
              ? 'injuries'
              : $route.meta.id === 'mentalPeriods'
              ? 'mental'
              : $route.meta.id
          }.png`
        "
        alt=""
      /><img
        class="c-content__main-image u-1-of-2"
        :src="
          `${$publicPath}/assets/girls-${
            $route.meta.id === 'injuriesPeriods'
              ? 'injuries'
              : $route.meta.id === 'mentalPeriods'
              ? 'mental'
              : $route.meta.id
          }.png`
        "
        alt=""
      />
    </div>
    <div class="o-layout__item c-content__text" v-html="htmlContent" />
    <div
      v-if="htmlContentReferences.length > 0"
      class="o-layout__item c-content__text"
    >
      <div class="c-content__text-ref" v-html="htmlContentReferences" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Chart from "@/components/Chart";
import content from "@/content";

export default {
  name: "ChartPage",
  components: { Chart },
  data() {
    return {
      htmlContent: "",
      htmlContentReferences: ""
    };
  },
  mounted() {
    this.setContentText(0);
    this.setReferences();
  },
  methods: {
    onGenderSelect(gender) {
      this.$store.dispatch("setGender", gender);
      this.setContentText(gender.value === "both" ? 0 : this.agePeriod);
    },
    onRangeClick(range) {
      this.$store.dispatch("setAgePeriod", range - 1);
    },
    setContentText(index) {
      index = !content[this.page].periods[index] ? 0 : index;
      content[this.page].periods[index].then(response => {
        if (response) {
          this.htmlContent = response.default.replaceAll(
            "/assets",
            `${this.$publicPath}/assets`
          );
        }
      });
    },
    setReferences() {
      if (content[this.page].references) {
        content[this.page].references.then(response => {
          this.htmlContentReferences = response.default.replaceAll(
            "/assets",
            `${this.$publicPath}/assets`
          );
        });
      }
    }
  },
  watch: {
    $route: {
      handler: function(to, from) {
        this.$nextTick(() => {
          this.setContentText(this.agePeriod);
        });
      },
      immediate: true
    },
    agePeriod(newValue, oldValue) {
      this.setContentText(newValue);
    }
  },
  computed: {
    pageType() {
      return this.$route.meta.type;
    },
    page() {
      return this.$route.meta.id;
    },
    agePeriods() {
      return content[`agePeriods_${this.pageType}_${this.gender.value}`];
    },
    agePeriod: {
      get() {
        return this.$store.state.agePeriod;
      },
      set(value) {
        this.$store.dispatch("setAgePeriod", value);
      }
    },
    ...mapState({
      gender: state => state.gender,
      genders: state => state.genders
    })
  }
};
</script>
